<template>
  <div>
    <div id="anexo"></div>
    <table id="a4" :class="'a4 pg1'" width="100%" border="0">
      <tbody>
        <tr>
          <td class="td">
            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td valign="top" style="padding-top: 5px">
                    <img
                      v-if="contrato.empresa == 'Viajar Resorts'"
                      src="@/assets/images/viajar-resorts.png"
                      alt="logo"
                      style="height: 70px"
                    />
                    <img
                      v-if="contrato.empresa == 'Voe Simples'"
                      src="@/assets/images/voe-simples.png"
                      alt="logo"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    align="center"
                    style="
                      font-size: 18px;
                      padding-top: 10px;
                      padding-bottom: 10px;
                    "
                  >
                    <strong style="font-weight: 600">ANEXO</strong>
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    align="center"
                    style="
                      background: #e5e5e5;
                      color: #444;
                      font-size: 14px;
                      padding: 5px 10px;
                      font-weight: 600;
                    "
                  >
                    VIAJANTES RELACIONADOS
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              width="100%"
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 13px; background: #f9f9f9"
            >
              <tr>
                <td
                  width="5%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <strong style="font-weight: 600">#</strong>
                </td>
                <td
                  width="75%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">NOME </strong>(Primeiro nome
                  e sobrenome)
                </td>
                <td
                  width="20%"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">NASCIMENTO</strong>
                </td>
              </tr>
            </table>

            <table
              width="100%"
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 13px"
            >
              <tr
                v-for="(viajante, index) in contrato.viajantes"
                :key="viajante"
              >
                <td
                  width="5%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <strong style="font-weight: 600">{{ index + 1 }}</strong>
                </td>
                <td
                  width="75%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  {{ viajante.nome }}
                </td>
                <td
                  width="20%"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  {{ viajante.nascimento }}
                </td>
              </tr>

              <tr>
                <td
                  colspan="3"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 0px 0;
                    border-style: solid;
                    padding: 10px 0 5px;
                    font-size: 10px;
                  "
                >
                  <p>
                    *Se o contratante for passageiro, o mesmo deverá constar na
                    relação acima.
                  </p>
                </td>
              </tr>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td valign="top">
                    <!--  HOTEL ---->
                    <table
                      width="100%"
                      border="0"
                      v-if="contrato.listaHoteis.length > 0"
                    >
                      <tbody>
                        <tr>
                          <td style="padding-top: 20px">
                            <table width="100%" border="0">
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style="
                                      background: #e5e5e5;
                                      color: #444;
                                      font-size: 14px;
                                      padding: 5px 10px;
                                      font-weight: 600;
                                    "
                                  >
                                    DADOS DA HOSPEDAGEM
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <table
                              width="100%"
                              border="0"
                              v-for="hotel in contrato.listaHoteis"
                              :key="hotel"
                            >
                              <tbody>
                                <tr>
                                  <td style="padding-bottom: 20px">
                                    <table
                                      width="100%"
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      style="font-size: 13px"
                                    >
                                      <tr>
                                        <td
                                          colspan="4"
                                          style="
                                            border-color: #eee;
                                            border-width: 0 0px 1px 0;
                                            border-style: solid;
                                            padding: 5px;
                                          "
                                        >
                                          <strong style="font-weight: 600"
                                            >NOME DO HOTEL:
                                          </strong>
                                          {{ hotel.nome }}
                                        </td>
                                      </tr>
                                      <tr
                                        v-for="quarto in hotel.acomodacoes"
                                        :key="quarto"
                                      >
                                        <td
                                          width="500"
                                          style="
                                            border-color: #eee;
                                            border-width: 0 1px 1px 0;
                                            border-style: solid;
                                            padding: 5px;
                                          "
                                        >
                                          <strong style="font-weight: 600">
                                            ACOMODAÇÃO
                                            {{ quarto.id }}:
                                          </strong>
                                          {{ quarto.nome }}
                                        </td>
                                        <td
                                          width="199"
                                          style="
                                            border-color: #eee;
                                            border-width: 0 0px 1px 0;
                                            border-style: solid;
                                            padding: 5px;
                                          "
                                        >
                                          <strong style="font-weight: 600">
                                            TIPO:
                                          </strong>
                                          {{ quarto.tipo }}
                                        </td>
                                      </tr>
                                    </table>

                                    <table
                                      width="100%"
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      style="font-size: 13px"
                                    >
                                      <tr>
                                        <td
                                          style="
                                            border-color: #eee;
                                            border-width: 0 0px 1px 0;
                                            border-style: solid;
                                            padding: 5px;
                                          "
                                        >
                                          <strong style="font-weight: 600"
                                            >REGIME ALIMENTAR:
                                          </strong>
                                          {{ hotel.regime }}
                                        </td>
                                      </tr>
                                    </table>

                                    <table
                                      width="100%"
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      style="font-size: 13px"
                                    >
                                      <tr>
                                        <td
                                          width="342"
                                          style="
                                            border-color: #eee;
                                            border-width: 0 1px 1px 0;
                                            border-style: solid;
                                            padding: 5px;
                                          "
                                        >
                                          <strong style="font-weight: 600"
                                            >CHECKIN:
                                          </strong>
                                          {{ hotel.checkin }}
                                        </td>
                                        <td
                                          width="538"
                                          colspan="4"
                                          style="
                                            border-color: #eee;
                                            border-width: 0 0px 1px 0;
                                            border-style: solid;
                                            padding: 5px;
                                          "
                                        >
                                          <strong style="font-weight: 600"
                                            >CHECKOUT:
                                          </strong>
                                          {{ hotel.checkout }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- AEREO -->
                    <div v-if="contrato.aereo.voos.length > 0">
                      <table
                        v-for="voo in contrato.aereo.voos"
                        :key="voo.id"
                        width="100%"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              colspan="10"
                              align="center"
                              style="padding-bottom: 10px"
                            ></td>
                          </tr>
                          <tr>
                            <td
                              colspan="10"
                              align="center"
                              style="
                                background: #e5e5e5;
                                color: #444;
                                font-size: 14px;
                                padding: 5px 10px;
                                font-weight: 600;
                                margin-top: 10px;
                              "
                            >
                              <strong style="font-weight: 600"
                                >DADOS DO TRANSPORTE AÉREO #{{ voo.id }}</strong
                              >
                            </td>
                          </tr>
                          <tr style="font-size: 13px">
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              TRECHO
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              CIA
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              VÔO
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              PARADAS
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              EMBARQUE
                            </td>

                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              DESEMBARQUE
                            </td>

                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              DURAÇÂO
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              ORIGEM
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              DESTINO
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 0px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              CLASSE
                            </td>
                          </tr>
                          <tr
                            style="font-size: 12px"
                            v-for="trecho in voo.trechos"
                            :key="trecho.id"
                          >
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.id }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.cia }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.numero }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.paradas }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.data_saida }}
                              {{ trecho.horario_saida }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.data_chegada }}
                              {{ trecho.horario_chegada }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.duracao }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.origem }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 1px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.destino }}
                            </td>
                            <td
                              style="
                                border-color: rgb(238, 238, 238);
                                border-width: 0px 0px 1px 0px;
                                border-style: solid;
                                padding: 5px;
                                text-align: center;
                              "
                            >
                              {{ trecho.classe }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!--  SERVIÇOS ---->
                    <table
                      v-if="contrato.listaServicos.length > 0"
                      width="100%"
                      border="0"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <table width="100%" border="0">
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style="padding-bottom: 30px"
                                  ></td>
                                </tr>
                              </tbody>
                            </table>
                            <table width="100%" border="0">
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style="
                                      background: #e5e5e5;
                                      color: #444;
                                      font-size: 14px;
                                      padding: 5px 10px;
                                      font-weight: 600;
                                    "
                                  >
                                    SERVIÇOS
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="font-size: 13px"
                            >
                              <tr
                                v-for="servico in contrato.listaServicos"
                                :key="servico"
                              >
                                <td
                                  width="898"
                                  style="
                                    border-color: #eee;
                                    border-width: 0 0px 1px 0;
                                    border-style: solid;
                                    padding: 5px;
                                  "
                                >
                                  {{ servico.nome }}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <!--  REGRAS ---->
            <table v-if="contrato.politicas.length > 0" width="100%" border="0">
              <tbody>
                <tr>
                  <td>
                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td align="center" style="padding-bottom: 30px"></td>
                        </tr>
                      </tbody>
                    </table>
                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            style="background: #e5e5e5; color: #444; font-size: 14px border:0 ;  padding: 5px 10px; font-weight: 600"
                          >
                            CONDIÇÕES E REGRAS
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      width="100%"
                      border="0"
                      v-for="(politica, index) in contrato.politicas"
                      :key="index"
                    >
                      <tbody>
                        <tr>
                          <td style="padding: 0">
                            <table width="100%" border="0">
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style="
                                      background: #f9f9f9;
                                      border-color: #eee;
                                      font-size: 12px;
                                      border-width: 1px 0px 1px 0;
                                      border-style: solid;
                                      padding: 5px;
                                    "
                                  >
                                    <strong style="font-weight: 600">
                                      {{ politica.titulo }}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="font-size: 13px"
                            >
                              <tr>
                                <td
                                  width="898"
                                  style="
                                    border-color: #eee;
                                    border-width: 0 0px 0px 0;
                                    border-style: solid;
                                    padding: 15px 5px 30px;
                                    font-size: 11px;
                                    text-align: justify;
                                    color: #666;
                                  "
                                  v-html="politica.texto"
                                ></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="html2pdf__page-break avoidThisRow"></div>
  </div>
</template>

<script>
export default {
  name: "TravelContractAttachment",
  props: {
    id: Number,
    contract: Object,
    contrato: Object,
    numeroViajantes: Number,
    numeroHoteis: Number,
    numeroTrechos: Number,
    numeroPoliticas: Number,
  },
  methods: {
    calcFirstPage(index) {
      if (this.numeroViajantes > 4 && this.numeroHoteis > 1) {
        return index < 1;
      }
      return index < 1;
    },
    calcSecondPage(index) {
      return index > 0;
    },
    breakToSecondPage() {
      if (this.numeroViajantes > 4 && this.numeroHoteis > 1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
